import { html, css } from 'lit-element';
import { productChangeFrequency, optoutProduct } from '../core/actions';
import { withChildOptions } from '../core/resolveProperties';
import { OptinStatus, mapStateToProps as mapStateToPropsOptinStatus } from './OptinStatus';
import { mapStateToProps, frequencyText } from './FrequencyStatus';
import { connect } from '../core/connect';
import { defaultFrequency } from '../core/props';
import { configSelector } from '../core/selectors';

export class OptinSelect extends withChildOptions(OptinStatus) {
  static get properties() {
    return {
      ...super.properties,
      frequencies: { type: Array, attribute: false },
      frequency: { type: String },
      defaultFrequency
    };
  }

  static get styles() {
    return css`
      :host {
        display: inline-block;
        cursor: pointer;
        background-color: var(--og-select-bg-color, #fff);
        border: var(--og-select-border, 1px solid #aaa);
        border-radius: var(--og-select-border-radius, 0.5em);
        border-width: var(--og-select-border-width, 1px);
        box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
      }
    `;
  }

  get currentFrequency() {
    if (!this.subscribed) {
      return 'optedOut';
    }
    if (this.frequency) {
      return this.frequency;
    }
    return this.defaultFrequency;
  }

  onOptinChange(value) {
    if (value === 'optedOut') {
      this.optoutProduct(this.product, this.offer);
    } else {
      this.productChangeFrequency(this.product, value, this.offer);
    }
  }

  render() {
    const { options: childOptions } = this.childOptions;
    let options;
    if (this.frequencies?.length) {
      options = [
        ...([childOptions.find(option => option.value === 'optedOut')] || []),
        ...this.frequencies.map((value, ix) => ({
          value,
          text:
            this.frequenciesText && ix in this.frequenciesText
              ? this.frequenciesText[ix]
              : frequencyText(value, this.defaultFrequency)
        }))
      ];
    } else {
      options = childOptions;
    }

    return html`
      <og-select
        .options="${options}"
        .selected="${this.currentFrequency}"
        .onChange="${({ target: { value } }) => this.onOptinChange(value)}"
      ></og-select>
    `;
  }
}

export const ConnectedOptinSelect = connect(
  (state, ownProps) => ({
    ...mapStateToPropsOptinStatus(state, ownProps),
    ...mapStateToProps(state, ownProps),
    ...configSelector(state, ownProps, 'frequencies')
  }),
  { productChangeFrequency, optoutProduct }
)(OptinSelect);

export default ConnectedOptinSelect;
