import platform from '../platform';
import { ENV_PROD, ENV_STAGING, STAGING_STATIC_HOST, STATIC_HOST } from './constants';

export function onReady(fn) {
  if (document.readyState === 'loading') {
    window.addEventListener('DOMContentLoaded', fn);
  } else {
    fn();
  }
}

export function getMainJs(): HTMLScriptElement {
  return document.querySelector(
    [
      `script[src^="https://${STATIC_HOST}"]`,
      `script[src^="https://${STAGING_STATIC_HOST}"]`,
      `script[src^="http://${STATIC_HOST}"]`,
      `script[src^="http://${STAGING_STATIC_HOST}"]`
    ].join(',')
  ) as HTMLScriptElement;
}
/**
 *
 * @returns array of two elements [merchantId, env];
 */
export function resolveEnvAndMerchant() {
  const script = getMainJs();
  if (!script) return [];
  const url = new URL(script.src);
  const env = url.host.startsWith(ENV_STAGING) ? ENV_STAGING : ENV_PROD;
  const merchantId = url.pathname.split('/')[1];

  if (!env && !merchantId) return [];

  return [merchantId, env, script];
}

export const safeProductId = product => {
  if (!product ) return '';
  let productId = `${product.id || product}`;
  if (platform?.shopify_selling_plans) {
    // we can't avoid make offer request since we need to know the upsell group and autoship by default
    // cart offer contains <product>:<cart_id>, we care about product only
    productId = productId.split(':')[0];
  }
  return productId;
};
/**
 * Attempts to auto initialize the offer library reading the merchantId and env from
 * integration script i.e. <script src="http://static.ordergroove...."/>.
 * Useful when local develop using http redirects
 */
export function autoInitializeOffers(offers) {
  if (offers.isReady()) return;
  const [merchantId, env] = resolveEnvAndMerchant();
  if (!env && !merchantId) return;
  offers.initialize(merchantId, env);
}

export const clearCookie = cookieId => {
  // clear existing OG auth cookie
  document.cookie = `${cookieId}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export function getCookieValue(cookieId) {
  const cookie = document.cookie.match(`(^|;) ?${cookieId}=([^;]*)(;|$)`);
  return cookie ? cookie[2] : null;
}
