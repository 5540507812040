import { html } from 'lit-element';
import { optinProduct } from '../core/actions';
import { OptinStatus, mapStateToProps } from './OptinStatus';
import { connect } from '../core/connect';
import { defaultFrequency } from '../core/props';
import { resolveProduct } from '../core/resolveProperties';

export class OptinButton extends OptinStatus {
  static get properties() {
    return {
      ...super.properties,
      frequency: { type: String, reflect: true },
      defaultFrequency,
      optinButtonLabel: { type: String }
    };
  }

  handleClick(ev) {
    this.optinProduct(
      resolveProduct(this),
      this.offer ? this.offer.defaultFrequency : this.defaultFrequency,
      this.offer
    );
    ev.preventDefault();
  }

  render() {
    return html`
      <slot name="default">
        <button id="action-trigger" class="btn radio ${this.subscribed ? 'active' : ''}"></button>
        <label for="action-trigger">
          <slot>
            <slot name="label"><og-text key="offerOptInLabel"></og-text></slot>
          </slot>
        </label>
      </slot>
    `;
  }
}

export const ConnectedOptinButton = connect(mapStateToProps, { optinProduct })(OptinButton);

export default ConnectedOptinButton;
